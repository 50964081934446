html {
    height: 100%;
    width: 100%;
}

body {
    font-family: 'Quicksand', sans-serif;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #fcfcfc;
}

img {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0vh auto;
}

header {
    min-height: 100vh;
}


.centerHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10vh 2.5em;
    border-bottom: 1px solid #e8e8e8;
}

.termsOfService{
    padding: 10vh 10em;
    border-bottom: 1px solid #e8e8e8;
}

.privacyPolicy, .about {
    padding: 10vh 20em;
    border-bottom: 1px solid #e8e8e8;
}

@media (max-width: 992px) {
    .termsOfService, .privacyPolicy, .about {
        padding: 10vh 0;

   }
}

h1, h2, h3, h4, h5 {
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}

h4 {
    font-size: 12px;
}

.centerContent {
    background: #fcfcfc;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 24px;
}

.previewSection {
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: center;
            justify-content: center;
    border-top: 1px solid #e8e8e8;
    padding: 0em 0em;

}

.cardSection {
    /* display: flex; */
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10em 5em;
    margin: 0 auto;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;

}

@media (max-width: 992px) {
    .previewSection {
        padding: 5em 0em;
    }
    .cardSection {
        padding: 5em 0em;
   }
}

.ant-card {
    margin: 2em auto;
}

.ant-card-meta-description {
    color: #4A4A4A;
}

.ant-card-bordered {
    /* border: 1px solid; */
    box-shadow: 0px 1px 5px #FF6376;
}

.ant-card-hoverable:hover {
    box-shadow: 0 2px 8px #FF3F57;
}

.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
    border-bottom: 1px solid #e8e8e8;
}

.ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom: 0;
    color: #ffffff;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 0;
    display: inline-block;

}

/* @media (max-width: 600px) {
    .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
        display: block;
        float: left;
   }
} */

